import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbMomentjsAdapter } from './adapters/ngb-momentjs.adapter';
import { HttpClientModule } from '@angular/common/http';
import { FieldErrorsComponent } from './components/forms/field-errors.component';
import { SignupFieldComponent } from './components/signup-field/signup-field.component';
import { CircleCheckboxComponent } from './components/circle-chekbox/circle-checkbox.component';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';
import { TextMiddleLineComponent } from './components/text-middle-line/text-middle-line.component';
import { SignInWithGoogleComponent } from './components/sign-in-with-google/sign-in-with-google.component';
import { PasswordValidationStatusComponent } from './components/pasword-validation-status/pasword-validation-status.component';
import { HttpLoaderComponent } from './components/http-loader/http-loader.component';
import { CustomDropdownComponent } from './components/forms/custom-dropdown/custom-dropdown.component';
import { DayPickerComponent } from './components/day-picker/day-picker.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { CircleSidebarComponent } from './components/circle-sidebar/circle-sidebar.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { CallToActionComponent } from './components/call-to-action/call-to-action.component';
import { ExpandableBoxComponent } from './components/expandable-box/expandable-box.component';
import { CircleDateComponent } from './components/circle-date/circle-date.component';
import { UpcomingTestComponent } from './components/upcoming-test/upcoming-test.component';
import { UpcomingTestTimerComponent } from './components/upcoming-test/upcoming-test-timer/upcoming-test-timer.component';
import { UpcomingTestIncompleteActionComponent } from './components/upcoming-test/upcoming-test-incomplete-action/upcoming-test-incomplete-action.component';
import { NoBookingComponent } from './components/no-booking/no-booking.component';
import { UpcomingTestOptionsComponent } from './components/upcoming-test/upcoming-test-options/upcoming-test-options.component';
import { AccountLayoutComponent } from './components/account-layout/account-layout.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { BookingCancelledComponent } from './components/booking-cancelled/booking-cancelled.component';
import { BookingCancelledOptionsComponent } from './components/booking-cancelled/booking-cancelled-options/booking-cancelled-options.component';
import { MonthPickerButtonComponent } from './components/month-picker-button/month-picker-button.component';
import { PastTestComponent } from './components/past-test/past-test.component';
import { PastTestOptionsComponent } from './components/past-test/past-test-options/past-test-options.component';
import { PastTestChildComponent } from './components/past-test/past-test-child/past-test-child.component';
import { CustomInputComponent } from './components/forms/custom-input/custom-input.component';
import { AddressDetailsFormComponent } from './components/forms/address-details-form/address-details-form.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { PassportDetailsFormComponent } from './components/forms/passport-details-form/passport-details-form.component';
import { UploadPassportFormComponent } from './components/forms/upload-passport-form/upload-passport-form.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TwoButtonComponent } from './components/two-button/two-button.component';
import { NgxDynamicTabindexDirective } from './directives/app-apply-tab-index.directive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MultiActionButtonComponent } from './components/multi-action-button/multi-action-button.component';
import { SignupDetailFormComponent } from './components/forms/signup-detail-form/signup-detail-form.component';
import { SurveyFormComponent } from './components/forms/survey-form/survey-form.component';
import { UpcomingTestUnpaidComponent } from './components/upcoming-test/upcoming-test-unpaid/upcoming-test-unpaid.component';
import { ContentModalComponent } from './components/content-modal/content-modal.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TimeLineComponent } from './components/time-line/time-line.component';
import { UpcomingTestTimelineComponent } from './components/upcoming-test-timeline/upcoming-test-timeline.component';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { LiveChatComponent } from './components/live-chat/live-chat.component';
import { IolNotAvailableComponent } from './components/iol-not-available/iol-not-available.component';
import { VpnErrorMsgComponent } from '../pages/booking/vpn-error-modal/vpn-err-modal.component';
import { CreditCardComponent } from './components/payment/credit-card/credit-card.component';
import { JourneyComponent } from './components/journey/journey.component';
import { JourneyButtonComponent } from './components/journey/journey-button/journey-button.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WizardComponent } from './components/wizard/wizard.component';
import { StepTemplateComponent } from './components/wizard/step-template/step-template.component';
import { FeeRowComponent } from '../pages/booking/confirmation/fee-row/fee-row.component';
import { BillingAddressFormComponent } from './components/forms/billing-address-form/billing-address-form.component';
import { SearchInputComponent } from './components/forms/search-input/search-input.component';
import { InformationBoxComponent } from './components/information-box/information-box.component';
import { TestDetailsCardComponent } from './components/test-details-card/test-details-card.component';
import { PaypalComponent } from './components/payment/paypal/paypal.component';
import { PaymentContainerComponent } from './components/payment/payment-container/payment-container.component';
import { IsPaymentCardDirective } from './directives/app-is-payment-card.directive';
import { PanelDirective } from './directives/app-payment-panel.directive';
import { OfflineComponent } from './components/payment/offline/offline.component';
import {
  UpcomingTestOfflinePaymentInfoComponent
} from './components/upcoming-test/upcoming-test-offline-payment-info/upcoming-test-offline-payment-info.component';
import {
  UpcomingTestOfflinePaymentInfoV2Component
} from './components/upcoming-test/upcoming-test-offline-payment-info-v2/upcoming-test-offline-payment-info-v2.component';
import { OfflineWizardComponent } from './components/payment/offline/offline-wizard.component';
import { OfflineRequestContentComponent } from './components/payment/offline/offline-request-content.component';
import { IdentityDocumentUploadFormComponent } from './components/forms/identity-document-upload-form/identity-document-upload-form.component';
import { IdentityDetailsFormComponent } from './components/forms/identity-details-form/identity-details-form.component';
import {
  TestDetailsContentComponent
} from './components/test-details-card/test-details-content/test-details-content.component';
import { PersonalInfoFormComponent } from './components/forms/personal-info-form/personal-info-form.component';
import { IELTSInfoFormComponent } from './components/forms/ielts-info-form/ielts-info-form.component';
import { StripeComponent } from './components/payment/stripe/stripe.component';
import { PaymentStatusComponent } from './components/upcoming-test/payment-status/payment-status.component';
import { UploadConsentFormModalComponent } from './components/upload-consent-form-modal/upload-consent-form-modal.component';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { PaystackComponent } from './components/payment/paystack/paystack.component';
import { UnderageConsentFormModalComponent } from './components/underage-consent-form-modal/underage-consent-form-modal.component';
import { UnderageIolModalComponent } from './components/underage-iol-modal/underage-iol-modal.component';
import { VeritransComponent } from './components/payment/veritrans/veritrans.component';
import { TipsComponent } from 'shared/components/payment/tips/tips.component';
import { TipsLoaderComponent } from './components/payment/tips/tips-loader/tips-loader.component';
import { YesNoQuestionComponent } from './components/forms/yes-no-question/yes-no-question.component';
import { UkviQuestionsFormComponent } from 'shared/components/forms/ukvi-questions-form/ukvi-questions-form.component';
import { BankAlfalahComponent } from './components/payment/bank-alfalah/bank-alfalah.component';
import { MockCreditCardComponent } from 'shared/components/payment/mock-credit-card/mock-credit-card.component';
import { UploadTypeComponent } from 'shared/components/forms/application-document-upload-form/upload-type/upload-type.component';
import { ApplicationDocumentUploadFormComponent } from 'shared/components/forms/application-document-upload-form/application-document-upload-form.component';
import { BracComponent } from './components/payment/brac/brac.component';
import { CircularBuffer } from './services/buffer.service';
import { DocumentUploadFormActionsComponent } from './components/forms/document-upload-form-actions/document-upload-form-actions.component';
import { ESewaComponent } from './components/payment/e-sewa/e-sewa.component';
import { UploadStatusComponent } from './components/forms/application-document-upload-form/upload-status/upload-status.component';
import { WebcamModalComponent } from 'shared/components/webcam-modal/webcam-modal.component';
import { CropModalComponent } from 'shared/components/crop-modal/crop-modal.component';
import { PaydollarComponent } from './components/payment/paydollar/paydollar.component';
import { UkviQuestionsFormV2Component } from './components/forms/ukvi-questions-form-v2/ukvi-questions-form.component';
import { YesNoQuestionV2Component } from './components/forms/yes-no-question-v2/yes-no-question.component';
import { NewebpayComponent } from './components/payment/newebpay/newebpay.component';
import { CustomBxInputComponent } from './components/custom-bx-input/custom-bx-input.component';
import { NewebpayCallbackComponent } from './components/payment/newebpay/newebpay-callback/newebpay-callback.component';
import { CustomBxDropdownComponent } from './components/custom-bx-dropdown/custom-bx-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InipayComponent } from './components/payment/inipay/inipay.component';
import { StripeCallbackComponent } from './components/payment/stripe/stripe-callback/stripe-callback.component';
import { InipayCallbackComponent } from './components/payment/inipay/inipay-callback/inipay-callback.component';
import { NoResultComponent } from '../app/shared/components/no-result/no-result.component';
import { HsbcOmniComponent } from './components/payment/hsbc-omni/hsbc-omni.component';
import { HsbcOmniCallbackComponent } from './components/payment/hsbc-omni/hsbc-omni-callback/hsbc-omni-callback.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    ImageCropperModule,
    AngularResizeEventModule,
    FeatureToggleModule,
    CarouselModule,
    NgbAccordionModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbPopoverModule,
    NgbAlertModule,
    NgbAccordionModule,
    NgSelectModule
  ],
  declarations: [
    FieldErrorsComponent,
    SignupFieldComponent,
    CircleCheckboxComponent,
    ProgressBarComponent,
    FormWrapperComponent,
    TextMiddleLineComponent,
    SignInWithGoogleComponent,
    PasswordValidationStatusComponent,
    HttpLoaderComponent,
    CustomDropdownComponent,
    DayPickerComponent,
    TimePickerComponent,
    CircleSidebarComponent,
    NavMenuComponent,
    CallToActionComponent,
    ExpandableBoxComponent,
    CircleDateComponent,
    UpcomingTestComponent,
    UpcomingTestTimerComponent,
    UpcomingTestIncompleteActionComponent,
    NoBookingComponent,
    UpcomingTestOptionsComponent,
    AccountLayoutComponent,
    ConfirmModalComponent,
    BookingCancelledComponent,
    BookingCancelledOptionsComponent,
    MonthPickerButtonComponent,
    PastTestComponent,
    PastTestOptionsComponent,
    PastTestChildComponent,
    CustomInputComponent,
    SearchInputComponent,
    AddressDetailsFormComponent,
    CountdownTimerComponent,
    PassportDetailsFormComponent,
    UploadPassportFormComponent,
    TwoButtonComponent,
    NgxDynamicTabindexDirective,
    MultiActionButtonComponent,
    PersonalInfoFormComponent,
    IELTSInfoFormComponent,
    SignupDetailFormComponent,
    SurveyFormComponent,
    UpcomingTestUnpaidComponent,
    ContentModalComponent,
    DropdownComponent,
    ChangePasswordComponent,
    TimeLineComponent,
    UpcomingTestTimelineComponent,
    LiveChatComponent,
    IolNotAvailableComponent,
    VpnErrorMsgComponent,
    CreditCardComponent,
    MockCreditCardComponent,
    JourneyComponent,
    JourneyButtonComponent,
    WizardComponent,
    StepTemplateComponent,
    FeeRowComponent,
    BillingAddressFormComponent,
    InformationBoxComponent,
    TestDetailsCardComponent,
    PaypalComponent,
    PaymentContainerComponent,
    OfflineComponent,
    OfflineWizardComponent,
    OfflineRequestContentComponent,
    UpcomingTestOfflinePaymentInfoComponent,
    IsPaymentCardDirective,
    PanelDirective,
    IdentityDocumentUploadFormComponent,
    IdentityDetailsFormComponent,
    TestDetailsContentComponent,
    StripeComponent,
    TipsComponent,
    PaymentStatusComponent,
    UploadConsentFormModalComponent,
    FileDragDropDirective,
    PaystackComponent,
    UnderageConsentFormModalComponent,
    UnderageIolModalComponent,
    VeritransComponent,
    TipsLoaderComponent,
    YesNoQuestionComponent,
    YesNoQuestionV2Component,
    UkviQuestionsFormComponent,
    UkviQuestionsFormV2Component,
    BankAlfalahComponent,
    UploadTypeComponent,
    BracComponent,
    ApplicationDocumentUploadFormComponent,
    DocumentUploadFormActionsComponent,
    ESewaComponent,
    UploadStatusComponent,
    WebcamModalComponent,
    CropModalComponent,
    PaydollarComponent,
    NewebpayComponent,
    CustomBxInputComponent,
    NewebpayCallbackComponent,
    CustomBxDropdownComponent,
    StripeCallbackComponent,
    NoResultComponent,
    InipayComponent,
    InipayCallbackComponent,
    UpcomingTestOfflinePaymentInfoV2Component,
    HsbcOmniComponent,
    HsbcOmniCallbackComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbPopoverModule,
    NgbAlertModule,
    NgbAccordionModule,
    FieldErrorsComponent,
    SignupFieldComponent,
    CircleCheckboxComponent,
    ProgressBarComponent,
    FormWrapperComponent,
    TextMiddleLineComponent,
    SignInWithGoogleComponent,
    PasswordValidationStatusComponent,
    HttpLoaderComponent,
    CustomDropdownComponent,
    DayPickerComponent,
    TimePickerComponent,
    CircleSidebarComponent,
    NavMenuComponent,
    CallToActionComponent,
    ExpandableBoxComponent,
    CircleDateComponent,
    UpcomingTestComponent,
    UpcomingTestTimerComponent,
    UpcomingTestIncompleteActionComponent,
    NoBookingComponent,
    UpcomingTestOptionsComponent,
    AccountLayoutComponent,
    ConfirmModalComponent,
    BookingCancelledComponent,
    BookingCancelledOptionsComponent,
    MonthPickerButtonComponent,
    PastTestComponent,
    PastTestOptionsComponent,
    PastTestChildComponent,
    CustomInputComponent,
    SearchInputComponent,
    AddressDetailsFormComponent,
    CountdownTimerComponent,
    PassportDetailsFormComponent,
    UploadPassportFormComponent,
    TwoButtonComponent,
    NgxDynamicTabindexDirective,
    MultiActionButtonComponent,
    PersonalInfoFormComponent,
    IELTSInfoFormComponent,
    SignupDetailFormComponent,
    SurveyFormComponent,
    UpcomingTestUnpaidComponent,
    ContentModalComponent,
    DropdownComponent,
    TimeLineComponent,
    LiveChatComponent,
    IolNotAvailableComponent,
    VpnErrorMsgComponent,
    CreditCardComponent,
    MockCreditCardComponent,
    JourneyComponent,
    WizardComponent,
    StepTemplateComponent,
    FeeRowComponent,
    BillingAddressFormComponent,
    InformationBoxComponent,
    TestDetailsCardComponent,
    PaypalComponent,
    PaymentContainerComponent,
    OfflineComponent,
    OfflineWizardComponent,
    OfflineRequestContentComponent,
    UpcomingTestOfflinePaymentInfoComponent,
    IsPaymentCardDirective,
    PanelDirective,
    IdentityDocumentUploadFormComponent,
    IdentityDetailsFormComponent,
    TestDetailsContentComponent,
    StripeComponent,
    TipsLoaderComponent,
    TipsComponent,
    UploadConsentFormModalComponent,
    FileDragDropDirective,
    PaystackComponent,
    UnderageConsentFormModalComponent,
    UnderageIolModalComponent,
    VeritransComponent,
    YesNoQuestionComponent,
    YesNoQuestionV2Component,
    UkviQuestionsFormComponent,
    UkviQuestionsFormV2Component,
    BankAlfalahComponent,
    UploadTypeComponent,
    BracComponent,
    ApplicationDocumentUploadFormComponent,
    ESewaComponent,
    WebcamModalComponent,
    PaydollarComponent,
    NewebpayComponent,
    CustomBxInputComponent,
    CustomBxDropdownComponent,
    NoResultComponent,
    InipayComponent,
    StripeCallbackComponent,
    InipayCallbackComponent,
    UpcomingTestOfflinePaymentInfoV2Component,
    HsbcOmniComponent
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbMomentjsAdapter },
    provideNgxMask(),
    CircularBuffer
  ],
})
export class SharedModule { }
