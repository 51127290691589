import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export type IState = {
  password?: string,
  email?: string,
  code?: string,
  completeForgetPassword?: boolean
  validationMessage?: ''
};

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  paymentNameChanged: EventEmitter<string> = new EventEmitter<string>();
  triggerSubmit: EventEmitter<any> = new EventEmitter();
  
  private state$ = new BehaviorSubject<IState>({
  });
 
  changeState(myChange) {
    this.state$.next(myChange);
  }

  getState() {
    return this.state$.asObservable();
  }

  updatePaymentName(newName: string) {
    this.paymentNameChanged.emit(newName);
  }

  triggerOnSubmit(componentName: string, typeVal?:any): void {
    const paymentDetails ={
      componentName: componentName,
      typeVal: typeVal
    }
    this.triggerSubmit.emit(paymentDetails);
  }

}
