import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SetProductType } from 'src/app/pages/booking/store/booking.actions';
import { setCallToActionState } from 'src/app/store/my-tests/my-tests.actions';
import { CallToActionState } from 'src/app/store/my-tests/my-tests.reducer';
import { isOSRIOCProduct } from 'shared/utils/is-ioc-product';


@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent {
  @Input() message = '';
  @Input() route = '';
  @Input() cta = '';
  @Input() application = null;
  @Input() hasCustomMessage = false;
  @Output() onBeforeCall: EventEmitter<string> = new EventEmitter();
  showMessage = true;
  hasUnpaidOSRApp: boolean;
  constructor(private router: Router, private store: Store<{ bookingStore, myTestsStore }>) {
  }

  onActionBtnClick(route: string) {
    if (isOSRIOCProduct(this.application?.productName)) {
      this.store.dispatch(setCallToActionState({ callToActionState: CallToActionState.OSR_PRE_PAYMENT }));
      this.router.navigate(['/my-tests']);
    } else {
      this.store.dispatch(SetProductType({
        isNotIOLProduct: false
      }));
      this.onBeforeCall.emit(route);
      this.router.navigate([route]);
    }
  }
}
