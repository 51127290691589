import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsewaPaymentService, PaymentStatus, PaymentStatusRequest } from '@idp-education/ors-esewa-payment-bff-client-v1';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import * as uuid from 'uuid';

@Component({
  selector: 'app-e-sewa-callback',
  templateUrl: './e-sewa-callback.component.html',
  styleUrls: ['./e-sewa-callback.component.scss']
})
export class ESewaCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  title: string;
  acceptText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub: Subscription;
  activatedRouteSub: Subscription;
  apiCallingSub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private esewaPaymentService: EsewaPaymentService
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.apiCallingSub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.eSewaStatus();
  }

  modalpopupDescrption(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.NOTFOUND:
      case PaymentStatus.PENDING:
      case 'CANCELLED':
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.acceptText = 'Payment Cancelled';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  eSewaStatus() {
    this.esewaPaymentService.configuration.accessToken = this.userToken;
    this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      this.activatedRouteSub = this.activatedRoute.params.subscribe((params) => {
        let receiptId = '';
        let receiptNumber = '';
        let signature = '';
        let txData = '';
        if (params.status.toLowerCase().includes('failed')) {
          receiptId = queryParams.get('order_id');
          receiptNumber = queryParams.get('order_number');
          signature = queryParams.get('signature');
      } else {
          txData = queryParams.get('data');
      }
        const statusPayload: PaymentStatusRequest = {
          receiptId,
          receiptNumber,
          signature,
          txData
        };
        this.apiCallingSub = this.esewaPaymentService.paymentStatus(statusPayload, uuid.v4()).subscribe(response => {
          this.modalpopupDescrption(response.status);
        }, error => {
          if (error.status === 401) {
            this.modalpopupDescrption('Unauthorised');
          } else {
            this.modalpopupDescrption('');
          }
        });
      });
    });
  }
}
