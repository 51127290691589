import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ViewportService } from 'shared/services/viewport.service';

/**
 * ## UKVI Questions Form
 *
 * This component is responsible for rending the required form for UKVI test takers.
 *
 *
 * ### @Input() questionForm
 *
 * The input form group is considered to be initialized like the following
 * ```typescript
 * new UntypedFormGroup({
 *       isForVisa: new UntypedFormControl(true, [Validators.required]),
 *       isHighlyTrustedStatus: new UntypedFormControl(true, [Validators.required]),
 *       isReferred: new UntypedFormControl(true, [Validators.required]),
 *       referralName: new UntypedFormControl(null, [Validators.required])
 *     });
 * ```
 * These name should keep updated if the form has been changed based on new business logic
 *
 *
 * ### Input() showInfoBox
 *
 * A flag to show/hide the info box on the template
 */
@Component({
  selector: 'app-ukvi-questions-form',
  templateUrl: './ukvi-questions-form.component.html',
  styleUrls: ['./ukvi-questions-form.component.scss'],
})
export class UkviQuestionsFormComponent implements OnChanges, OnDestroy {
  @Input() questionsForm: UntypedFormGroup;
  @Input() showInfoBox: boolean;
  @Input() showTermsAndConditions: boolean;
  @Input() isTestForUKVIDisabled: boolean;
  @Input() readonly: boolean;

  destroyed = new Subject<void>();
  sub: Subscription;
  testLocalTimezone: string;
  isMobile = false;

  constructor(
    private router: Router,
    private store: Store<{ bookingStore }>
  ) {
    this.getTestLocalTimezone();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionsForm.currentValue) {
      if (this.questionsForm) {
        this.listenToIsReferredChange();
      }
    }
  }

  private listenToIsReferredChange(): void {
    this.onIsReferredChange(!!this.questionsForm.get('isUKVIReferral').value);
    this.questionsForm.get('isUKVIReferral').valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => this.onIsReferredChange(data));
  }

  onIsReferredChange(data: boolean): void {
    if (data) {
      this.setValidators('referralName', Validators.required);
      this.questionsForm.get('referralName').enable();
    } else {
      this.clearValidation('referralName');
      this.questionsForm.get('referralName').disable();
      this.questionsForm.get('referralName').setValue('');
    }
  }
  private clearValidation(fieldName) {
    const ctrl = this.questionsForm.get(fieldName);
    ctrl.clearValidators();
    ctrl.setErrors(null);
    this.questionsForm.updateValueAndValidity({ emitEvent: false });
  }

  private setValidators(fieldName, validator, errors?) {
    const ctrl = this.questionsForm.get(fieldName);
    ctrl.setValidators(validator);
    if (errors) {
      ctrl.setErrors(errors);
    }
    this.questionsForm.updateValueAndValidity();
  }

  private getTestLocalTimezone() {
    this.sub = this.store.select(appState => appState?.bookingStore?.testLocalTimezone).subscribe(x => {
      if (x) {
        this.testLocalTimezone = x;
      }
    });
  }

  openTermAndCondition() {
    const hongKong = this.testLocalTimezone?.toLowerCase() === 'asia/hong_kong';
    const url = '/term-and-conditions-ukvi' + (hongKong ? '-hongkong' : '');
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])), '_blank');
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.sub?.unsubscribe();
  }
}
