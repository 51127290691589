import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentStatus, PaystackPaymentService } from '@idp-education/ors-paystack-payment-bff-client-v1';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { Auth } from 'aws-amplify';
import { EORLocalStorageItemKeys, IOCLocalStorageItemKeys, OSRLocalStorageItemKeys, PaymentStates } from 'pages/payment/payment.enum';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-paystack-callback',
  templateUrl: './paystack-callback.component.html',
  styleUrls: ['./paystack-callback.component.scss']
})
export class PaystackCallbackComponent implements OnInit {

  @ViewChild('paymentErrorModalPaypal') paymentErrorModalPaypal: ConfirmModalComponent;
  title: string;
  acceptText: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paystackPaymentService: PaystackPaymentService,
    private loadingService: LoadingService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      const userToken = session.getAccessToken().getJwtToken();
      const paymentType = localStorage.getItem('paymentType');
      let CurrentApp: Application;
      switch (paymentType) {
        case 'IOC':
          CurrentApp = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore)).currentApplication;
          break;
        case 'EOR':
          CurrentApp = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication));
          break;
        case 'OSR':
          CurrentApp = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
          break;
        default:
          break;
      }
      const applicationId = CurrentApp.id;
      this.paystackPaymentService.configuration.accessToken = userToken;
      this.route.queryParamMap.subscribe((queryParams) => {
        const reference = queryParams.get('reference');
        this.paystackPaymentService.paystackPaymentStatus(reference, applicationId, uuid.v4()).subscribe((response) => {
          if (response?.status) {
            switch (response.status) {
              case PaymentStatus.Success:
                this.navigationService.storePaymentState(PaymentStates.COMPLETED);
                this.navigationService.navigateOnPaymentType(paymentType);
                break;
              case PaymentStatus.Failed:
                this.title = `Your payment failed. Please retry the payment.`;
                this.acceptText = 'Retry Payment';
                this.paymentErrorModalPaypal.open();
                this.loadingService.decreaseLoadingCounter();
                break;
              case PaymentStatus.Abandoned:
                this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
                this.acceptText = 'Payment Cancelled';
                this.paymentErrorModalPaypal.open();
                this.loadingService.decreaseLoadingCounter();
                break;
              case PaymentStatus.Pending:
                this.title = `Your payment is Pending confirmation from Paystack. Please check your IELTS Profile after some time for the latest status or contact your test centre.`;
                this.acceptText = 'Return to My Account';
                this.paymentErrorModalPaypal.open();
                this.loadingService.decreaseLoadingCounter();
                break;
              default:
                this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
                this.acceptText = 'Return to My Account';
                this.paymentErrorModalPaypal.open();
                this.loadingService.decreaseLoadingCounter();
                break;
            }
          }
        }, error => {
          this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
          this.acceptText = 'Return to My Account';
          this.paymentErrorModalPaypal.open();
          this.loadingService.decreaseLoadingCounter();
        });
      });
    });
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentErrorModalPaypal.closeModal(true);
  }
}
