import { ApplicationsService } from './../services/applications.service';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
declare let EF: any;
@Injectable({
  providedIn: 'root'
})
export class EverFlowHelper {
  constructor(private applicationService: ApplicationsService) {
  }
  initiateAffliateTracking() {
    if (typeof EF !== 'undefined' && EF && EF.urlParameter('oid') && EF.urlParameter('affid')) {
      EF.click({
        offer_id: EF.urlParameter('oid'),
        affiliate_id: EF.urlParameter('affid'),
        sub1: EF.urlParameter('sub1'),
        sub2: EF.urlParameter('sub2'),
        sub3: EF.urlParameter('sub3'),
        sub4: EF.urlParameter('sub4'),
        sub5: EF.urlParameter('sub5'),
        uid: EF.urlParameter('uid'),
        source_id: EF.urlParameter('source_id'),
        transaction_id: EF.urlParameter('_ef_transaction_id'),
      }).then((transactionId) => {
        localStorage.setItem('everflowData', JSON.stringify({
            offerId: EF.urlParameter('oid'),
            affId: EF.urlParameter('affid'),
            date: DateTime.now().toUTC().toISO(),
            transactionId
          }));
      });
    }
  }
  processEverflowConversion(currentApplication: Application) {
    const everFlowData = JSON.parse(localStorage.getItem('everflowData'));
    // tslint:disable-next-line: max-line-length
    const applicationPayment = this.applicationService.getLatestApplicationPayment(currentApplication.applicationPayments);
    const lrwSession = currentApplication.bookings?.find(x => !x.isSpeaking);
    if (everFlowData && everFlowData?.offerId && everFlowData?.transactionId) {
      EF.conversion({
        aid: 1,  // Everflow suggested for global advertiser events.
        adv_event_id: 1,
        adv1: lrwSession?.bookableProductName, // Application booking product name.
        adv2: lrwSession?.testCentreCode, // Application booking test center idp code.
        adv3: lrwSession?.testLocationId, // Application booking test centre location name.
        adv4: lrwSession?.startDateTimeUtc, // Application booking LRW session date.
        adv5: applicationPayment?.receiptNumber, // Application booking payment receipt number.
        // transaction_id: everFlowData.transactionId, // The everflow transaction id stored in application cache or cookie
        // tslint:disable-next-line: max-line-length
        amount: applicationPayment?.amount, // BX test fee paid.
        // tslint:disable-next-line: max-line-length
        order_id: applicationPayment?.id,  // BX test application payment Id
        // user_id: currentApplication.userProfile.id, // BX user profile Id
        email: currentApplication?.userProfile?.emailAddress,
        parameters: {
          // tslint:disable-next-line: object-literal-key-quotes
          'application_id': currentApplication?.id, // if order_id is Everflow specific
          // tslint:disable-next-line: object-literal-key-quotes
          'user_profile_id': currentApplication?.userProfile?.id, // if user_id is Everflow specific
          // tslint:disable-next-line: object-literal-key-quotes
          'session_id': lrwSession?.sessionId,
          // tslint:disable-next-line: object-literal-key-quotes
          'currency': applicationPayment?.currency ? applicationPayment?.currency : 'AUD',
          // tslint:disable-next-line: object-literal-key-quotes
          'lrw_test_date_time': lrwSession?.startDateTimeUtc,
          // tslint:disable-next-line: object-literal-key-quotes
          'speaking_test_date_time': currentApplication.bookings.find(x => x.isSpeaking).startDateTimeUtc
        }
      }).then(x => {
        localStorage.removeItem('everflowData');
      });
    }
  }
}
