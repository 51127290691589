import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from './loading-service.service';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, concatMap, delay, first, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { setAllUser, setLogOut } from 'store/user/user.actions';
export const authToken = new Subject<string>();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _token: string;
  public get token(): string {
    return this._token;
  }

  public set token(v: string) {
    this._token = v;
    authToken.next(this._token);
  }
  private worker: Worker;
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private store: Store,
  ) {
    if (typeof Worker !== 'undefined') {
      this.startRefreshToken();
    }
  }
  public startRefreshToken() {
    this.ConfigureHub();
    this.setWorker();
  }

  private setWorker() {
    this.worker = new Worker('./../../../assets/worker-js/refresh-token.worker.js', { type: 'module' });
    this.worker.onmessage = (e) => {
      if (e.data === 'call') {
        this.refreshToken();
      }
    };
  }

  public ConfigureHub() {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn' || data.payload.event === 'configured') {
        this.worker.postMessage('start');
      } else if (data.payload.event === 'signOut') {
        this.worker.postMessage('stop');
      }
    });
  }
  signOut(): void {
    this.loadingService.increaseLoadingCounter();
    const defaultErrMsg = 'Oops .. something went wrong. Please try again later';
    Auth.signOut({ global: true })
      .then(() => {
        this.store.dispatch(setLogOut({ logout: true }));
        this.router.navigate(['/account/signin']).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => this.toastr.error(err?.message || defaultErrMsg))
      .finally(() => this.loadingService.decreaseLoadingCounter());
  }
  authenticatedUser(): Observable<{
    isAuth: boolean,
    user: any
  }> {
    return of('').pipe(delay(1), switchMap(() => from(Auth.currentAuthenticatedUser()).pipe(
      first(),
      catchError((e) => {
        return of(false);
      }),
      map((user) => ({ isAuth: !!user, user }))
    )));
  }
  checkToken(): Observable<{ sub: string } | null> {
    return this.authenticatedUser().pipe(concatMap(({ isAuth: isAuthenticated, user }) => {
      if (isAuthenticated) {
        return (new Observable<{ sub: string } | null>((observer) => {
          try {
            Auth.currentSession().then(session => {
              this.token = session.getAccessToken().getJwtToken();
              observer.next({ sub: user?.attributes?.sub });
              observer.complete();
            });
          } catch (error) {
            observer.next(null);
            observer.complete();
          }
        }));
      } else {
        return of(null);
      }
    }), first());
  }
  public clear() {
    this.token = '';
    setTimeout(() => {
      this.store.dispatch(setAllUser({ userDetails: null, userFetchedTime: null, userToken: null }));
    }, 1);
  }
  public fetchCurrentUserInfo(): Promise<any> {
    return Auth.currentUserInfo();
  }
  public currentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }
  async refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) {
          this.signOut();
        }
        this.token = session.getAccessToken().getJwtToken();
      });
    } catch (e) {
      this.signOut();
    }
  }
}
