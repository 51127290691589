import { Auth } from 'aws-amplify';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GtmHelper {
  isLoggedIn: boolean;
  userId: string;
  getGtmEvents(pagename: string) {
    if (pagename === 'Booking Complete') {
      return {
        event: 'Pagenav',
        websitename: 'Booking Experience',
        language: 'English',
        userstatus: this.isLoggedIn ? 'Logged in' : 'Anonymous',
        testtaker: this.userId,
        pagename: pagename ? pagename : 'Welcome to IELTS Online'
      };
    } else {
      return {
        event: 'Pagenav',
        websitename: 'Booking Experience',
        language: 'English',
        userstatus: this.isLoggedIn ? 'Logged in' : 'Anonymous',
        pagename: pagename ? pagename : 'Welcome to IELTS Online'
      };
    }
  }
  checkUserStatus() {
    Auth.currentAuthenticatedUser().then(user => {
      this.isLoggedIn = true;
      this.userId = user.attributes.sub;
    }).catch(error => {
      this.isLoggedIn = false;
      this.userId = undefined;
    });
  }
}
