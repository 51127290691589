import { createReducer, createSelector, on, Selector } from '@ngrx/store';
import {
  ResetState,
  setDateRange,
  setGoToApplUpdate,
  setLocation, setOriginalApplication,
  setOSRCurrentApplication,
  setSelectedTest,
  setSsrComponent,
  setSSRFee,
  setTestCentreDetail, setTestType, setCallToActionState, setUnpaidOSRApp
} from './my-tests.actions';
import { Application, DashboardItem, ProductFee } from '@idp-education/ors-test-taker-bff-client-v1';
import { ISearchCriteria } from './search-criteria.interface';

export enum CallToActionState {
    OSR_PRE_PAYMENT = 'osr-pre-payment',
    OSR_POST_PAYMENT = 'osr-post-payment',
    EOR_PRE_PAYMENT = 'eor-pre-payment',
    EOR_POST_PAYMENT = 'eor-post-payment'
}
export interface IState {
  originalApplication: DashboardItem | undefined;
  selectedTest: any;
  criteria: ISearchCriteria;
  testCentreName: string;
  testCentreAddress: string;
  ssrComponent: string;
  ssrFee: ProductFee;
  callToActionState: CallToActionState | undefined;
  hasUnpaidApp: boolean;
  currentOSRApplication: Application;
  goToApplUpdate: boolean;
}

const initialState = {
  selectedTest: {},
  testCentreName: '',
  testCentreAddress: '',
  ssrComponent: '',
  currentOSRApplication: null,
  ssrFee: undefined,
  criteria: {
    fromDate: '',
    toDate: '',
    locLabel: '',
    locLabelShort: '',
    testType: '',
    testFormat: 'CD' // TODO: remove hardcoded value
  } as ISearchCriteria,
  goToApplUpdate: false
} as IState;

const reducer = createReducer(
  initialState,
  on(setSelectedTest, (state, {selectedTest}) => ({
    ...state,
    selectedTest
  })),
  on(setTestCentreDetail, (state, {testCentreName, testCentreAddress}) => ({
    ...state,
    testCentreName,
    testCentreAddress
  })),
  on(setSsrComponent, (state, {ssrComponent}) => ({
    ...state,
    ssrComponent
  })),
  on(setLocation, (state, {currentLoc, locLabel, locLabelShort}) => ({
    ...state,
    criteria: {
      ...state.criteria,
      currentLoc,
      locLabel,
      locLabelShort
    }
  })),
  on(setDateRange, (state, {from, to}) => ({
    ...state,
    criteria: {
      ...state.criteria,
      fromDate: from,
      toDate: to
    }
  })),
  on(setOriginalApplication, (state, {application}) => ({
    ...state,
    originalApplication: application
  })),
  on(setTestType, (state, {testType}) => ({
    ...state,
    criteria: {
      ...state.criteria,
      testType
    }
  })),
  on(setSSRFee, (state, {ssrFee}) => ({
    ...state, ssrFee
  })),
  on(ResetState, (state) => ({
    ...initialState,
    selectedTest: state.selectedTest,
    testCentreName: state.testCentreName,
    testCentreAddress: state.testCentreAddress,
    ssrComponent: state.ssrComponent,
    criteria: state.criteria,
    callToActionState: state.callToActionState,
    hasUnpaidApp: state.hasUnpaidApp,
    currentOSRApplication: state.currentOSRApplication
  })),
  on(setCallToActionState, (state, {callToActionState}) => ({
    ...state,
    callToActionState
  })),
  on(setUnpaidOSRApp, (state, {hasUnpaidApp}) => ({
    ...state,
    hasUnpaidApp
  })),
  on(setOSRCurrentApplication, (state, payload: {currentOSRApplication}) => ({
    ...state,
    currentOSRApplication: payload.currentOSRApplication
  })),
  on(setGoToApplUpdate, (state, { goToApplUpdate }) => ({
    ...state,
    goToApplUpdate
  })),
);

export const getState = (state: { myTestsStore: IState }) => state.myTestsStore;

export const getSelectedTest = (state: IState) => state?.selectedTest;

export const getTestCentreName = (state: IState) => state.testCentreName;

export const getTestCentreAddress = (state: IState) => state.testCentreAddress;

export const getSsrComponent = (state: IState) => state?.ssrComponent;

export const getOriginalApplication = (state: IState) => state.originalApplication;

export const getTestType = (state: IState) => state.criteria.testType;

export const getTestFormat = (state: IState) => state.criteria.testFormat;

export const getSSRFee = (state: IState) => state?.ssrFee;

export const getCallToActionState = (state: IState) => state.callToActionState;

export const getUnpaidApp = (state: IState) => state.hasUnpaidApp;

export const getCurrentOSRApplication = (state: IState) => state?.currentOSRApplication;

export const getGoToApplUpdate = (state: IState) => state.goToApplUpdate;



export const myTestsReducer = (state: any, action: any) => {
  return reducer(state, action);
};


const createMyTestsSelector = (selectMethod) => createSelector(
  getState,
  selectMethod
);

export const selectSelectedTest: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getSelectedTest
);

export const selectTestCentreName: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getTestCentreName
);

export const selectTestCentreAddress: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getTestCentreAddress
);

export const selectSsrComponent: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getSsrComponent
);

export const selectOriginalApplication: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getOriginalApplication
);

export const selectTestType: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getTestType
);

export const selectTestFormat: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getTestFormat
);

export const selectSSRFee: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getSSRFee
);

export const selectCallToActionState: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getCallToActionState
);

export const selectUnpaidApp: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getUnpaidApp
);

export const selectOSRApplication: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getCurrentOSRApplication
);

export const selectGoToApplUpdate: Selector<{ myTestsStore: IState }, any> = createMyTestsSelector(
  getGoToApplUpdate
);

