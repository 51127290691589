<div class="d-flex flex-column" *ngIf="EORFee">
    <div class="d-md-block">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <div class="row">
                    <div class="col-6 font-color d-flex flex-wrap mb-5">
                        <div>Test Taker First Name </div>
                        <div>(as on trf)</div>
                    </div>
                    <div class="col-6 mb-5">{{EORFee?.application?.userProfile?.firstName}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 border-lg-left">
                <div class="row">
                    <div class="col-6 font-color d-flex flex-wrap mb-5 ">
                        <div>Test Taker Last Name </div>
                        <div>(as on trf)</div>
                    </div>
                    <div class="col-6 mb-5">{{EORFee?.application?.userProfile?.lastName}}</div>
                </div>
            </div>
        </div>
        <div class="row mb-1 ">
            <div class="col-lg-6 col-sm-12">
                <div class="row">
                    <div class="col-6 font-color mb-5">Candidate Number</div>
                    <div class="col-6 mb-5">{{application?.bookings[0]?.cmdsBookingDetail?.shortCandidateNumber}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 border-lg-left">
                <div class="row">
                    <div class="col-6 font-color mb-5">Test Center/Location</div>
                    <div class="col-6 mb-5">{{application?.bookings[0]?.testCentreCode}}</div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-lg-6 col-sm-12">
                <div class="row">
                    <div class="col-6 font-color mb-5">Test Type</div>
                    <div class="col-6 mb-5">{{EORFee?.application?.bookings[0]?.bookableProductName}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-none d-md-block hr">
        <hr>
    </div>
    <div>
        <div class="mb-3">
            <th class="heading">Please re-mark the following sections <span class="asterisk">*</span></th>
        </div>
        <form>

            <div class="form-row">
                <ng-container *ngFor='let item of skills;trackBy:identify'>
                    <div class="form-check form-check-inline d-flex align-items-md-center col">
                        <input type="checkbox" class="col-sm-4 form-check-input"
                            [id]="item.label" [formControl]="formData?.get(getLanguageSkill(item.key))">
                        <label class="form-check-label" [for]="item.label">{{item.label}}</label>
                    </div>
                </ng-container>

            </div>
        </form>
        <div class="mt-5">
            <div class="fee-detail d-flex justify-content-between border-bottom">
                <div class="fee-details cursor-pointer" tabindex="0"
                    (keydown.enter)="productFee?.charges?.length ? onFeeToggleClick() : _noop"
                    (click)="productFee?.charges?.length ? onFeeToggleClick() : _noop">
                    Fee details
                </div>
                <span class="arrow-space cursor-pointer" (click)="onFeeToggleClick()" (keyup)="onFeeToggleClick()"
                    *ngIf="productFee?.charges.length">
                    <img tabindex="0" (keydown.enter)="onFeeToggleClick()" class="btn btn-highlight"
                        [src]="feeToggle ? '../../../../assets/images/UpArrowRed.svg' : '../../../../assets/images/DownArrowRed.svg'"
                        alt="">
                </span>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="!feeToggle" *ngIf="productFee?.charges.length">
                <app-confirmation-fee-row [productFees]="productFee?.charges"
                    [currencyIsoCode]="productFee?.currencyIsoCode">
                </app-confirmation-fee-row>
            </div>
            <div class="row eor-fee pt-2 align-items-center">
                <div class="col price col-lg-6 pl-1" i18n>Total</div>
                <div class="col-1 price first-letter">{{((productFee?.totalAmount |
                    currency: productFee?.currencyIsoCode : 'symbol-narrow')?.substr(0,1))}}</div>
                <div class="price col-3 border-top border-bottom border-start pt-2 pb-2 ps-2 text-end">
                    {{ toFix(productFee?.totalAmount)}}
                </div>
                <div class="price col-3 col-lg-2 border-top border-bottom border-end  pt-2 pb-2 ps-2">{{
                    productFee?.currencyIsoCode}}</div>
            </div>
        </div>
    </div>
    <div class="mt-3">
        <div class="row">
            <div class="col">
                <h6 class="d-flex mt-1 privacy-link">
                    <div>Privacy Policy </div>
                    <div class="asterisk">*</div>
                </h6>
            </div>
        </div>
        <div class="form-group form-check d-flex align-items-md-center">
            <input [formControl]="formData?.get('policy')" type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">I have read and agreed to the
                <a href="https://www.idp.com/australia/privacy-policy/" rel="noopener" target="_blank">
                    Privacy Policy
                </a>
            </label>
        </div>
    </div>
</div>