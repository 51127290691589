import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { Observable, Subscriber } from 'rxjs';
import { UserProfileService } from './shared/services/user-profile.service';
import { SetLatestPageUrl } from './pages/booking/store/booking.actions';
import { first, take } from 'rxjs/operators';
import { setUtminSession } from 'shared/utils/utm-params-utils';
import { AuthService } from 'shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  userProfile;
  latestPage;

  constructor(private router: Router,
              private userProfileService: UserProfileService,
              private store: Store<{ userStore }>) {
    this.userProfileService.getUserProfile().pipe(first()).subscribe((uProfile) => {
      if (uProfile) {
        this.userProfile = uProfile;
      } else {
        this.userProfile = null;
      }
    }, () => { /* empty */ });
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((observer) => {
      Auth.currentAuthenticatedUser()
        .then((userData) => {
          if (userData?.attributes?.identities) {
            try {
              const identity = JSON.parse(userData.attributes.identities);
              if (
                Array.isArray(identity)
                && identity[0]
                && identity[0].providerName
                && identity[0].providerName.match(/google|facebook|SignInWithApple/gi)) {
                if (this.userProfile) {
                  observer.next(true);
                } else {
                  observer.next(this.router.parseUrl('/account/social-signup'));
                }
              } else {
                observer.next(true);
                observer.complete();
              }
            } catch (e) {
              observer.next(true);
              observer.complete();
            }
          } else if (this.userProfileService.getUserProfile().pipe(take(1))) {
            observer.next(true);
          } else {
            observer.next(this.router.parseUrl('/create-booking'));
            observer.complete();
          }
        })
        .catch(() => {
          setUtminSession();
          let latestPage = state.url;
          // remove query params if exist
          if (latestPage.indexOf('?') > -1) {
            latestPage = latestPage.substring(0, state.url.indexOf('?'));
          }
          this.store.dispatch(SetLatestPageUrl({ latestPage }));
          observer.next(this.router.parseUrl('/account'));
          observer.complete();
        })
        .finally(() => { /* empty */ });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuardService  {
  constructor(private router: Router, private userProfile: UserProfileService,  private authService: AuthService,) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((observer) => {
      Auth.currentAuthenticatedUser()
        .then(() => {
            this.userProfile.getUserProfile().subscribe({
              error: err => {
                if (err) {
                  this.authService.signOut();
                  localStorage.removeItem('fromLoginPage');
                }
              }, next: () => {
                try {
                  observer.next(this.router.parseUrl('/my-account'));
                } catch (error) {
                  observer.next(this.router.parseUrl('/my-account'));
                }
              }
            })
        })
        .catch(() => {
          observer.next(true);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }


}

@Injectable({
  providedIn: 'root'
})
export class SocialLoginAuthGuardService  {
  constructor(private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((observer) => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          observer.next(true);
        })
        .catch(() => {
          observer.next(this.router.parseUrl('/account'));
        })
        .finally(() => {
          observer.complete();
        });
    });
  }
}
