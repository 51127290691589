import { Injectable, OnDestroy } from '@angular/core';
import {
  Application, ApplicationOrganisation, ApplicationPayment, ApplicationService,
  ApplicationUserProfileUpdateRequest,
  ApplicationUserProfileUpdateResponse,
  ApplicationWithResultsResponse, CreateApplicationRequest, CreateApplicationResponse, CreateSSRApplicationRequest, MarketingDetails, SeltDetails, UtmTag
} from '@idp-education/ors-test-taker-bff-client-v1';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { from, of, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { AppIState } from '../../store/applications/application.reducer';
import { first, pluck } from 'rxjs/operators';
import { INonIOLProductFields } from '../models/components/nonIOLFields.modal';
import { selectOSRApplication } from 'src/app/store/my-tests/my-tests.reducer';
import { DateTime } from 'luxon';
import { Storage } from 'aws-amplify';
import { IBookingStoreState } from 'pages/booking/store/booking.reducer';
const luxonFormat = 'yyyy-MM-dd\'T\'HH:mm:ss';

const ProductId = ['8ccd6cf6-f06b-4910-a489-74863cb340c5', 'c623abd7-d823-4a6c-a8b9-46e4b5ff2b6f'];
const LocationID = ['12995a88-88a3-474e-846e-db3a3dfd2511'];

export const selectApp = createFeatureSelector('applicationsStore');
export const selectApplication = (applicationId: string) => createSelector(
  selectApp,
  (Applications: AppIState) => Applications.applicationsList.filter((app: CreateApplicationResponse) => app.id === applicationId)
);

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService implements OnDestroy {
  sub: Subscription = new Subscription();
  state: IBookingStoreState;
  testLocalTimezone: string;
  selectedOSRApp$ = this.store.select(selectOSRApplication);
  constructor(
    private store: Store<{ globalStore, userStore, applicationsStore, bookingStore, myTestsStore }>,
    private applicationService: ApplicationService
  ) {
    this.sub = this.store.select(appState => appState.bookingStore).subscribe(x => {
      this.state = x;
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  GenerateApplication(
    lrwTime: { to: DateTime, from: DateTime },
    speakingTime: { to: DateTime, from: DateTime },
    termId: string,
    nonIOLProductFields: INonIOLProductFields,
    UKVIForm?: SeltDetails): Observable<CreateApplicationResponse> {
    let lrwUTCDate;
    let speakingUTCDate;
    const productId = nonIOLProductFields?.isNotIOLProduct ? nonIOLProductFields.productId
      : ProductId[0];
    const locationId = nonIOLProductFields?.isNotIOLProduct ? nonIOLProductFields.testLocationId
      : LocationID[0];
    if (nonIOLProductFields?.isNotIOLProduct) {
      // Converting testlocation Time zone to UTC time.
      const lrwDate = lrwTime.from.toFormat(luxonFormat);
      lrwUTCDate = lrwTime.from.toUTC().toISO();
      const speakingDate = speakingTime.from.toFormat(luxonFormat);
      speakingUTCDate = speakingTime.from.toUTC().toISO();
    }
    const request = {
      lrwBookingCriteria: {
        bookableProductId: productId, startDateTimeUtc: nonIOLProductFields?.isNotIOLProduct ?
          lrwUTCDate : lrwTime.from.toUTC().toISO()
      },
      speakingBookingCriteria: {
        bookableProductId: productId, startDateTimeUtc: nonIOLProductFields?.isNotIOLProduct ?
          speakingUTCDate : speakingTime.from.toUTC().toISO()
      },
      testLocationId: locationId,
      timeZone: nonIOLProductFields?.isNotIOLProduct ? nonIOLProductFields?.testLocalTimezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
      termsAndConditionsVersion: termId,
      seltDetails: UKVIForm ? UKVIForm : undefined,
      referralPartnerId: this.state.referralPartnerId ? this.state.referralPartnerId : undefined,
      marketingDetails: this.getMarketingDetails()
    } as CreateApplicationRequest;
    return this.applicationService.createApplication(request);
  }
  GenerateSSRApplication(appId: string, testDetails, termVersion: string): Observable<any> {
    const request: CreateSSRApplicationRequest = {
      applicationId: appId,
      ssrBookingCriteria: {
        bookableProductId: testDetails?.externalBookableProductId,
        languageSkill: testDetails?.languageSkills.length > 0 ? testDetails?.languageSkills[0] : testDetails?.languageSkills,
        startDateTimeUtc: testDetails?.testStartUtcDatetime,
      },
      termsAndConditionsVersion: termVersion,
      testLocationId: testDetails?.testLocation?.externalReferenceId,
      timeZone: testDetails?.testLocalTimeZone,
    };
    return this.applicationService.createSSRApplication(request);
  }
  GetApplication(appId: string): Observable<Application> {
    return this.applicationService.getApplication(appId);
  }
  getApplicationOrganization(appId: string): Observable<ApplicationOrganisation[]> {
    return this.applicationService.getApplicationOrganisations(appId);
  }
  getApplicationResults(corrId: string, appId: string): Observable<ApplicationWithResultsResponse> {
    return this.applicationService.getApplicationResults(corrId, appId);
  }
  GetCurrentApplication(props: { force: boolean } = { force: false }): Observable<Application> {
    return new Observable((sub) => {
      this.store.select(appState => appState.applicationsStore.currentApplication).pipe(first()).subscribe(
        (data: Application) => {
          if (props.force && data) {
            this.GetApplication(data.id).subscribe(app => {
              sub.next(app);
            });
          } else if (!props.force && data) {
            sub.next(data);
          } else {
            sub.next(null);
          }
        }
      );
    });
  }
  GetCurrentOSRApplication(props: { force: boolean } = { force: false }): Observable<Application> {
    return new Observable((sub) => {
      this.selectedOSRApp$.pipe(first()).subscribe(
        (data: Application) => {
          if (props.force && data) {
            this.GetApplication(data.id).subscribe(app => {
              sub.next(app);
            });
          } else if (!props.force && data) {
            sub.next(data);
          } else {
            sub.next(null);
          }
        }
      );
    });
  }
  getPDFUrl(applicationId: string): Observable<string> {
    return this.applicationService.createApplicationResultPresignedUrl(applicationId).pipe(pluck('renditionFileUrl'));
  }

  getLatestApplicationPayment(payments: ApplicationPayment[]): ApplicationPayment {
    return payments?.reduce((a, b) => (new Date(a?.createdOn) > new Date(b?.createdOn) ? a : b));
  }

  updateApplicationUserProfile(
    applicationId: string,
    profile: ApplicationUserProfileUpdateRequest
  ): Observable<ApplicationUserProfileUpdateResponse> {
    return this.applicationService.applicationUserProfileUpdate(applicationId, profile);
  }

  uploadConsentFormToBucket(consentForm: File, config?: any): Observable<any> {
    if (consentForm) {
      return from(Storage.put(consentForm.name, consentForm, config));
    }
    return of('');
  }

  getMarketingDetails() {
    const referrerWebsite = sessionStorage.getItem('referrerUrl')
    const utmValue = sessionStorage.getItem('utm_value') || ''
    const urlSearchParams = new URLSearchParams(utmValue)
    if (urlSearchParams.get('u_mdm') && urlSearchParams.get('u_src')) {
      const finalUtmValue = {
        utmCampaign: urlSearchParams.get('u_cmp') || '',
        utmContent: urlSearchParams.get('u_cnt') || '',
        utmId: urlSearchParams.get('u_id') || '',
        utmMedium: urlSearchParams.get('u_mdm') || '',
        utmSource: urlSearchParams.get('u_src') || '',
        utmTerm: urlSearchParams.get('u_trm') || '',
      } as UtmTag
      const marketingDetails = {
        referrerUrl: referrerWebsite || document.referrer ,
        utmTag: finalUtmValue
      } as MarketingDetails
      return marketingDetails
    } else {
      return undefined
    }
  }
}
