import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankalfalahPaymentService, PaymentStatus } from '@idp-education/ors-bankalfalah-payment-bff-client-v1';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import * as uuid from 'uuid';
import { restoreLocalData } from '../../payment-utils/payment-utils';

@Component({
  selector: 'app-bank-alfalah-callback',
  templateUrl: './bank-alfalah-callback.component.html',
  styleUrls: ['./bank-alfalah-callback.component.scss']
})
export class BankAlfalahCallbackComponent implements OnInit, OnDestroy {
  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  title: string;
  acceptText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub: Subscription;
  activatedRouteSub: Subscription;
  apiCallingSub: Subscription;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private bankalfalahPaymentService: BankalfalahPaymentService
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.apiCallingSub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.bankAlfalahStatus();
  }

  modalpopupDescrption(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.acceptText = 'Payment Cancelled';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  bankAlfalahStatus() {
    this.bankalfalahPaymentService.configuration.accessToken = this.userToken;
    this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const orderId = queryParams.get('orderId');
      this.apiCallingSub = this.bankalfalahPaymentService.paymentStatus(uuid.v4(), orderId, this.applicationId).subscribe((response) => {
        this.modalpopupDescrption(response.status);
      }, error => {
        if (error.status === 401) {
          this.modalpopupDescrption('Unauthorised');
        } else {
          this.modalpopupDescrption('');
        }
      });
    });
  }
}
