<div class="container-fluid">
  <div class="">
    <div class="flexbox header-box mb-2">
      <div class="d-flex align-items-center">
        <h2 class="underlined-title">Confirmation</h2>
      </div>
    </div>
    <h3 class="mt-0 mb-1">
      Great work! Let's make sure these details are correct
    </h3>
    <app-test-details-card
      [ssrComponent]="ssrComponent$ | async"
      [testTime]="getTimeString$() | async"
      [duration]="getDuration$() | async"
      [testStartUtcDatetime]="getTestDate$() | async"
      (onBackButtonClick)="onBackButtonClick.emit()">
      <app-ssr-test-type testType
                         [type]="testType$ | async"></app-ssr-test-type>
      <app-ssr-list-item-test-format testFormat [format]="testFormat$ | async"
                                     [withIcon]="false"></app-ssr-list-item-test-format>
      <ng-container testLocationName>{{testCentreName$ | async}}</ng-container>
      <ng-container address>{{address$ | async}}</ng-container>
    </app-test-details-card>

    <app-confirm-modal #sameDateError [title] = sameDateErrorMessage
    imageUrl="/assets/images/Girl grey organic shape.png" [acceptText]="'Go to My Tests'" [showReject]="false"
    [imageClass]="'w-100'">
  </app-confirm-modal>
  </div>
  <div class="d-flex flex-column priceContainer mt-4">
    <div class="content d-flex flex-column ">
      <div class="w-100">
        <div class="fee-detail d-flex justify-content-between border-bottom">
          <div class="fee-details " (click)="onFeeToggleClick()" tabindex (keyup.enter)="onFeeToggleClick()">
            Fee details
          </div>
          <span class="arrow-space" (click)="onFeeToggleClick()" tabindex (keyup.enter)="onFeeToggleClick()">
            <img tabindex="0" class="btn btn-highlight" [src]="uparrow" alt="">
          </span>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="!feeToggle">
          <app-confirmation-fee-row [productFees]="productFee?.charges"
            [currencyIsoCode]="productFee?.currencyIsoCode"></app-confirmation-fee-row>
        </div>
        <div class="row p-2 d-flex align-items-center">
          <div class="total-font col-4 col-lg-6" i18n>Total</div>
          <div class="col-1 first-letter">{{ ((productFee?.totalAmount |
            currency: productFee?.currencyIsoCode : 'symbol-narrow') || '$').substr(0,1)}}</div>
          <div class="price col-3 border-top border-bottom border-start pt-2 pb-2 ps-2 text-end">
            {{ toFix(productFee?.totalAmount)}}
          </div>
          <div class="type col-3 col-lg-2 border-top border-bottom border-end  pt-2 pb-2 ps-2">{{
            productFee?.currencyIsoCode}}</div>
        </div>
        <label class="checkboxContainer mt-4 text-grey">
          <input type="checkbox" [(ngModel)]="termAndConditions" class="me-2">
          <span class="checkmark"></span>
          <span i18n>I agree to the </span>
          <a href="javascript:void()" (click)="openTermAndCondition()" class="btn-link text-grey" i18n> <u>Terms and
            Conditions</u></a>
        </label>
      </div>
      <div class="d-flex justify-content-center justify-content-lg-start align-items-center mt-2 w-100 flex-wrap">
        <button class="btn btn-multiAction btn-highlight btn-align"
          [disabled]= "!termAndConditions"
          (click)="onBookNowClicked()">
         Book now
        </button>
        <button class="btn-link btn-highlight btn text-grey cancel-button" (click)="onCancelClick.emit()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

