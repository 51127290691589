import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import { DateTime } from 'luxon';

export type IState = {
  userProfile?: UserProfileResponse,
  progressValue: number,
  currentStep?: number,
  totalSteps?: number,
  currentStepName?: string,
  selectedDate?: { to: DateTime, from: DateTime },
  LRWSelectedDate?: { to: DateTime, from: DateTime },
  color: 'blue' | 'purple',
};

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  private readonly initialState: IState = {
    progressValue: 0,
    currentStep: 0,
    totalSteps: 0,
    currentStepName: '',
    color: 'blue'
  };
  readonly timeFormat = 'yyyy-MM-dd HH:mm';
  state$ = new BehaviorSubject<IState>(this.initialState);
  private bookingObject = 'bookingObject';

  constructor() {
    const local = this.getFromLocalStorage() as any;
    /* istanbul ignore else */
    if (local !== this.initialState) {
      this.state$.next({
        ...local,
        selectedDate: {
          from:
            (local && local.selectedDate && local.selectedDate.from) ?
              DateTime.fromFormat(local.selectedDate.from || '', this.timeFormat) :
              null,
          to:
            local && local.selectedDate && local.selectedDate.to ?
              DateTime.fromFormat(local.selectedDate.to || '', this.timeFormat) :
              null,
        },
        LRWSelectedDate: {
          from:
            local && local.LRWSelectedDate && local.LRWSelectedDate.from ?
              DateTime.fromFormat(local.LRWSelectedDate.from || '', this.timeFormat) :
              null,
          to:
            local && local.LRWSelectedDate && local.LRWSelectedDate.to ?
              DateTime.fromFormat(local.LRWSelectedDate.to || '', this.timeFormat) :
              null,
        }
      } as IState);
    }
  }

  getFromLocalStorage(): IState | null {
    try {
      return JSON.parse(localStorage.getItem(this.bookingObject));
    } catch (error) {
      return null;
    }
  }

  changeState(myChange: IState) {
    this.state$.next(myChange);
    const temp = JSON.stringify({
      ...myChange,
      selectedDate: {
        to: myChange && myChange.selectedDate && myChange.selectedDate.to ? myChange.selectedDate.to.toFormat(this.timeFormat) : '',
        from: myChange && myChange.selectedDate && myChange.selectedDate.from ? myChange.selectedDate.from.toFormat(this.timeFormat) : ''
      },
      LRWSelectedDate: {
        to:
          myChange &&
          myChange.LRWSelectedDate &&
          myChange.LRWSelectedDate.to ? myChange.LRWSelectedDate.to.toFormat(this.timeFormat) : '',
        from: myChange && myChange.LRWSelectedDate && myChange.LRWSelectedDate.from ?
          myChange.LRWSelectedDate.from.toFormat(this.timeFormat) : ''
      },
    });
    localStorage.setItem(this.bookingObject, temp);
  }

  getState() {
    return this.state$.asObservable();
  }

  clearLocalStorage() {
    localStorage.removeItem(this.bookingObject);
  }

  clearState() {
    this.state$.next(this.initialState);
  }
}

