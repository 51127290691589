import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankalfalahPaymentService, PaymentStatus } from '@idp-education/ors-bankalfalah-payment-bff-client-v1';
import { BracPaymentService, PaymentStatus as BracPaymentStatus } from '@idp-education/ors-brac-payment-bff-client-v1';
import { EsewaPaymentService, PaymentStatusRequest, PaymentStatus as eSewaPaymentStatus } from '@idp-education/ors-esewa-payment-bff-client-v1';
import { PaydollarPaymentService, PaymentStatus as PayDollarStatus } from '@idp-education/ors-paydollar-payment-bff-client-v1';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { Auth } from 'aws-amplify';
import { EORLocalStorageItemKeys, IOCLocalStorageItemKeys, OSRLocalStorageItemKeys, PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-payments-callback',
  templateUrl: './payments-callback.component.html',
  styleUrls: ['./payments-callback.component.scss']
})
export class PaymentsCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  title: string;
  acceptText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub: Subscription;
  activatedRouteSub: Subscription;
  apiCallingSub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private bankalfalahPaymentService: BankalfalahPaymentService,
    private bracPaymentService: BracPaymentService,
    private esewaPaymentService: EsewaPaymentService,
    private paydollarPaymentService: PaydollarPaymentService,
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreLocalData();
    });
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.apiCallingSub?.unsubscribe();
  }

  restoreLocalData() {
    let CurrentApp: Application;
    switch (this.paymentType) {
      case 'IOC':
        CurrentApp = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore)).currentApplication;
        break;
      case 'EOR':
        CurrentApp = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication));
        break;
      case 'OSR':
        CurrentApp = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
        break;
      default:
        break;
    }
    this.applicationId = CurrentApp.id;
    this.navigatePaymentGateway();
  }

  navigatePaymentGateway() {
    this.paymentGateway = localStorage.getItem('paymentGateway');
    switch (this.paymentGateway) {
      case PaymentGateways.BANKALFALAH:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.bankAlfalahStatus(queryParams);
        });
        break;
      case PaymentGateways.BRAC:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.bracStatus(queryParams);
        });
        break;
      case PaymentGateways.ESEWA:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.activatedRouteSub = this.activatedRoute.params.subscribe((params) => {
            this.eSewaStatus(params, queryParams);
          });
        });
        break;
      case PaymentGateways.PAYDOLLAR:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.payDollarStatus(queryParams);
        });
        break;
      default:
        break;
    }
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  modalpopupDescrption(status) {
    if (this.paymentGateway === PaymentGateways.ESEWA && status === eSewaPaymentStatus.PENDING) {
      status = PaymentStatus.CANCELLED;
    }
    if (this.paymentGateway === PaymentGateways.PAYDOLLAR) {
      if (status === PayDollarStatus.PENDING) {
        status = '';
      } else if (status === PayDollarStatus.TIMEOUT) {
        status = PayDollarStatus.CANCELLED;
      }
    }
    switch (status) {
      case PaymentStatus.SUCCESS:
      case eSewaPaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
      case eSewaPaymentStatus.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
      case BracPaymentStatus.CANCELED:
      case eSewaPaymentStatus.NOTFOUND:
      case eSewaPaymentStatus.PENDING:
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.acceptText = 'Payment Cancelled';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  bankAlfalahStatus(queryParams) {
    this.bankalfalahPaymentService.configuration.accessToken = this.userToken;
    const orderId = queryParams.get('orderId');
    this.apiCallingSub = this.bankalfalahPaymentService.paymentStatus(uuid.v4(), orderId, this.applicationId).subscribe((response) => {
      this.modalpopupDescrption(response.status);
    }, error => {
      this.modalpopupDescrption('');
    });
  }

  bracStatus(queryParams) {
    this.bracPaymentService.configuration.accessToken = this.userToken;
    const requestBody = {
      receiptNumber: queryParams.get('orderId'),
      txData: queryParams.get('txData')
    };
    this.apiCallingSub = this.bracPaymentService.getPaymentStatus(uuid.v4(), requestBody).subscribe(response => {
      this.modalpopupDescrption(response.status);
    }, error => {
      this.modalpopupDescrption('');
    });
  }

  eSewaStatus(params, queryParams) {
    this.esewaPaymentService.configuration.accessToken = this.userToken;
    let receiptId = '';
    let receiptNumber = '';
    let signature = '';
    let txData = '';
    if (params.status.toLowerCase().includes('failed')) {
      receiptId = queryParams.get('order_id');
      receiptNumber = queryParams.get('order_number');
      signature = queryParams.get('signature');
    } else {
      txData = queryParams.get('data');
    }
    const statusPayload: PaymentStatusRequest = {
      receiptId,
      receiptNumber,
      signature,
      txData
    };
    this.apiCallingSub = this.esewaPaymentService.paymentStatus(statusPayload, uuid.v4()).subscribe(response => {
      this.modalpopupDescrption(response.status);
    }, error => {
      this.modalpopupDescrption('');
    });
  }

  payDollarStatus(queryParams) {
    this.paydollarPaymentService.configuration.accessToken = this.userToken;
    const receiptNumber = queryParams.get('Ref');
    const signature = queryParams.get('signature');
    const statusPayload = {
      receiptNumber,
      signature,
    };
    this.apiCallingSub = this.paydollarPaymentService.getPaymentStatus(uuid.v4(), statusPayload).subscribe(response => {
      this.modalpopupDescrption(response.status);
    }, error => {
      this.modalpopupDescrption('');
    });
  }
}
