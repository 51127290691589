import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import { HsbcomniPaymentService, PaymentStatus, PaymentStatusRequest } from '@idp-education/ors-hsbcomni-payment-bff-client-v1';
import * as uuid from 'uuid';
import { IPaymentText } from 'shared/interfaces/payment.interface';
import { PaymentStates } from 'pages/payment/payment.enum';

@Component({
  selector: 'app-hsbc-omni-callback',
  templateUrl: './hsbc-omni-callback.component.html',
  styleUrls: ['./hsbc-omni-callback.component.scss']
})
export class HsbcOmniCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  title: string;
  acceptText: string;
  subTitle: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  sub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private hsbcomniPaymentService: HsbcomniPaymentService
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.hsbcOmniStatus();
  }

  modalPopupDescrption(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = IPaymentText.failedTitle;
        this.acceptText = IPaymentText.failedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.PENDING:
        this.title = IPaymentText.pendingTitle;
        this.acceptText = IPaymentText.pendingAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.TIMEOUT:
      case PaymentStatus.CANCELLED:
        this.title = IPaymentText.cancelledTitle;
        this.acceptText = IPaymentText.cancelledAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorized':
      case 'UNAUTHORIZED':
        this.title = IPaymentText.unauthorizedTitle;
        this.acceptText = IPaymentText.unauthorizedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = IPaymentText.defaultTitle;
        this.acceptText = IPaymentText.defaultAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  hsbcOmniStatus() {
    this.hsbcomniPaymentService.configuration.accessToken = this.userToken;
    this.sub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const receiptId = queryParams.get('orderId');
      const signature = queryParams.get('signature');
      const receiptNumber = queryParams.get('orderNumber');
      const statusPayload: PaymentStatusRequest = {
        receiptId,
        receiptNumber,
        signature
      }
      const apiCallingSub = this.hsbcomniPaymentService.paymentStatus(uuid.v4(), statusPayload).subscribe(response => {
        this.modalPopupDescrption(response.status);
      }, error => {
        if (error.includes('Unauthorized Access')) {
          this.modalPopupDescrption('Unauthorized');
        } else {
          this.modalPopupDescrption('');
        }
      });
      this.sub.add(apiCallingSub);
    });
  }
}
