import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public isLoading = new BehaviorSubject<boolean>(false);
  private counter = new BehaviorSubject<number>(0);
  _counter = 0;
  /**
   * resetLoadingCounter
   */
  public resetLoadingCounter() {
    this._counter = 0;
    this.counter.next(this._counter);
  }
  public increaseLoadingCounter() {
    this.counter.next(this._counter + 1);
  }

  public decreaseLoadingCounter() {
    this.counter.next(this._counter - 1);
  }
  constructor() {
    this.counter.pipe(
      map((item: number) => Math.max(0, item)),
      tap((item: number) => this._counter = item)
    ).subscribe(count => {
      this.isLoading.next(count > 0);
    });
  }
}
