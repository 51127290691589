<form [formGroup]="form">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-dropdown id="title" title="Title" labelKey="name" idKey="value" i18n-placeholder placeholder="Title"
          [options]="userTitle" [formControl]="form?.get('title')" [showTick]="true" [isRequired]="true" [showValidationError]="true">
        </app-dropdown>
      </div>
      <div class="col-12 col-lg-6">
        <app-dropdown id="gender" title="Gender" [formControl]="form?.get('gender')" labelKey="name" idKey="id"
          i18n-placeholder placeholder="Gender" [options]="genderList" [showTick]="true" [isRequired]="true" [showValidationError]="true"
          [inputDisabled]="disableGender">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'firstName'" [form]="form" id='firstname' i18n-placeholder placeHolder="First name"
          [showError]="true" [required]="true" title="First name" (paste)="onlyAlphaNumeric($event)" (drop)="onlyAlphaNumeric($event)">
        </app-custom-input>
      </div>
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'lastName'" [form]="form" id='lastName' i18n-placeholder placeHolder="Family name"
          [showError]="true" title="Family name" (paste)="onlyAlphaNumeric($event)"
          (drop)="onlyAlphaNumeric($event)">
        </app-custom-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'mobileNumber'" [form]="form" id='mobileNumber' i18n-placeholder [required]="true"
          [type]="getAutomationTestToggle() ? null : 'phone'" i18n-tooltip tooltip="Your mobile number"
          [errorCMessage]="customMessages.mobileNumber" title="Mobile" [selectedCountryISO]="selectedcountryISO">
        </app-custom-input>
      </div>
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'birthDate'" [form]="form" id='birthDate' i18n-placeholder
          placeHolder="Date of Birth DD / MM / YYYY" [required]="true"
          [tooltip]="disableDateOfBirth?null:getBirthDateTooltip()" type="birthDate" mask="d0/M0/0000"
          [customMessage]='customMessages.birthDate' title="Date of Birth" [nextToLabelToolTip]="dateOfBirthToolTip" [nextToLabelToolTipClass]="dateOfBirthToolTipClass">
        </app-custom-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'email'" [form]="form" id='email' i18n-placeholder placeHolder="Email address"
          [required]="true" type="email" [validShowTick]="true" [customMessage]="customMessages.email"
          title="Email address">
        </app-custom-input>
      </div>
    </div>
    <div class="title-padding">
      <h2 class="heading m-0">
        ID information
      </h2>
      <span class="text-sm">Please complete your ID information</span>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <app-dropdown *ngIf="identificationTypes" labelKey="name" idKey='id' i18n-placeholder id="identificationTypes"
          placeholder="Identity Type" [options]="identificationTypes" [formControl]="form && form?.get('identityType')"
          [showTick]="true" i18n-title [inputDisabled]="disableIdType" title="Identity Type" [isRequired]="true"
          (onChangeState)="setExpiryDateMandatory()">
        </app-dropdown>
      </div>
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'identityNo'" [form]="form" placeHolder="Identity Number" i18n-placeholder
          [required]="!disableIdNumber" title="Identity Number" [showTick]="!disableIdNumber" [validShowTick]="true"
          maxLength="20">
        </app-custom-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-dropdown *ngIf="nationalityList" labelKey="name" idKey='id' i18n-placeholder id="nationality"
          placeholder="Country Of Nationality" [options]="nationalityList"
          [formControl]="form && form.get('countryOfNationality')" [addNewOptionId]="getNewOptionId(nationalityList)"
          [showTick]="!disableCountryOfNationality" i18n-title title="Country Of Nationality (per ID document)"
          [isRequired]="!disableCountryOfNationality" [inputDisabled]="disableCountryOfNationality">
        </app-dropdown>
      </div>
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'expiryDate'" [form]="form" id='expiryDate' [validShowTick]="true" i18n-placeholder
          placeHolder="Expiry Date DD / MM / YYYY"
          [required]="!disableExpiryDate && ID_CONSTANTS[identityType]?.isExpiryDateMandatory" [showError]="true"
          [showTick]="!disableExpiryDate" type="birthDate" i18n-title title="Expiry Date" mask="d0/M0/0000">
        </app-custom-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-custom-input [fcn]="'issuingAuthority'" [form]="form" id='issuingAuthority' i18n-placeholder
          placeHolder="ID issuing Authority" i18n-title [showTick]="true" [showError]="true" title="ID issuing Authority"
          [required]="!!isUKVIApplication">
        </app-custom-input>
      </div>
    </div>
  </div>
</form>
