import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { Auth } from 'aws-amplify';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import { ApprovalStatus, InipayPaymentService, VerifyStatus } from '@idp-education/ors-inipay-payment-bff-client-v1';
import { PaymentStates } from 'pages/payment/payment.enum';
import * as uuid from 'uuid';
import { IPaymentText } from 'shared/interfaces/payment.interface';

@Component({
  selector: 'app-inipay-callback',
  templateUrl: './inipay-callback.component.html',
  styleUrls: ['./inipay-callback.component.scss']
})
export class InipayCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  title: string;
  acceptText: string;
  subTitle: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  sub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private inipayPaymentService: InipayPaymentService
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp.id;
    this.iniPayStatus();
  }

  modalPopupDescrption(status) {
    switch (status) {
      case ApprovalStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case ApprovalStatus.FAILED:
        this.title = IPaymentText.failedTitle;
        this.acceptText = IPaymentText.failedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case ApprovalStatus.TIMEOUT:
      case VerifyStatus.CANCELLED:
        this.title = IPaymentText.cancelledTitle;
        this.acceptText = IPaymentText.cancelledAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case ApprovalStatus.UNAUTHORIZED:
      case VerifyStatus.UNAUTHORIZED:
        this.title = IPaymentText.unauthorizedTitle;
        this.acceptText = IPaymentText.unauthorizedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = IPaymentText.defaultTitle;
        this.acceptText = IPaymentText.defaultAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  iniPayStatus() {
    this.inipayPaymentService.configuration.accessToken = this.userToken;
    this.sub = this.activatedRoute.params.subscribe((params) => {
      const routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
        if (params.status.toLowerCase().includes('cancelled')) {
          this.verifyStatus(queryParams);
        } else if (params.status.toLowerCase().includes('next')) {
          this.approveStatus(queryParams);
        }
      });
      this.sub.add(routerSub);
    });
  }

  verifyStatus(queryParams) {
    const receiptId = queryParams.get('orderId');
    const receiptNumber = queryParams.get('orderNumber');
    const signature = queryParams.get('signature');
    const statusPayload = {
      receiptId,
      receiptNumber,
      signature,
    };
    const apiCallingSub = this.inipayPaymentService.paymentVerify(uuid.v4(), statusPayload).subscribe(response => {
      const encodedString = response.message;
      let decodedString = encodedString.replace(/\\x/g, '%'); // Convert \\x to % for proper URL decoding
      decodedString = decodeURIComponent(decodedString);
      this.subTitle = decodedString.replace(/'|b/g, '');
      this.modalPopupDescrption(response.status);
    }, error => {
      if (error.includes('Unauthorized Access')) {
        this.modalPopupDescrption(VerifyStatus.UNAUTHORIZED);
      } else {
        this.modalPopupDescrption('');
      }
    });
    this.sub.add(apiCallingSub);
  }

  approveStatus(queryParams) {
    let authTransactionId = queryParams.get('p_auth_tid');
    if (authTransactionId.includes('undefined')) {
      authTransactionId = '';
    }
    const idcName = queryParams.get('p_idcname');
    const receiptId = queryParams.get('p_noti');
    const receiptNumber = queryParams.get('p_oid');
    let message = queryParams.get('p_rmesg');
    if (message.includes('undefined')) {
      message = '';
    }
    const statusPayload = {
      authTransactionId,
      idcName,
      receiptId,
      receiptNumber,
      message
    };
    const apiCallingSub = this.inipayPaymentService.paymentApproval(uuid.v4(), statusPayload).subscribe(response => {
      this.subTitle = response.message;
      this.modalPopupDescrption(response.status);
    }, error => {
      if (error.includes('Unauthorized Access')) {
        this.modalPopupDescrption(ApprovalStatus.UNAUTHORIZED);
      } else {
        this.modalPopupDescrption('');
      }
    });
    this.sub.add(apiCallingSub);
  }
}
