import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1/model/application';
import { Store } from '@ngrx/store';
import { PaymentUtilService } from 'pages/payment/payment-util.service';
import { EORLocalStorageItemKeys, IOCLocalStorageItemKeys, OSRLocalStorageItemKeys } from 'pages/payment/payment.enum';
import { selectOfflinePayment } from 'pages/payment/store/payment.reducer';
import { first } from 'rxjs/operators';
import { selectOSRApplication, selectSSRFee, selectSelectedTest, selectSsrComponent } from 'store/my-tests/my-tests.reducer';
import { ProductFee } from '@idp-education/ors-test-taker-bff-client-v1';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private paymentUtilService: PaymentUtilService,
    private router: Router,
    private store: Store<{
      myTestsStore;
      paymentStore;
      bookingStore;
      applicationsStore;
    }>,
  ) { }

  storeIOCInfo(application) {
    const speakingAndLrw = this.paymentUtilService.getSpeakingAndLRW(
      application
    );
    const speakingtesttime = this.paymentUtilService.getSpeakingTestTime(
      speakingAndLrw?.speaking
    );
    const lrwtesttime = this.paymentUtilService.getLRWTestTime(
      speakingAndLrw?.lrw
    );
    localStorage.setItem(
      IOCLocalStorageItemKeys.speakingtesttime,
      JSON.stringify(speakingtesttime)
    );
    localStorage.setItem(
      IOCLocalStorageItemKeys.lrwtesttime,
      JSON.stringify(lrwtesttime)
    );
    this.store
      .select((state) => state.bookingStore.isNotIOLProduct)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          IOCLocalStorageItemKeys.isNotIOLProduct,
          JSON.stringify(res)
        );
      });

    this.store
      .select((appState) => appState.bookingStore.testLocalTimezone)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          IOCLocalStorageItemKeys.testLocalTimezone,
          JSON.stringify(res)
        );
      });

    this.store
      .select((appState) => appState.applicationsStore)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          IOCLocalStorageItemKeys.applicationsStore,
          JSON.stringify(res)
        );
      });

    this.store
      .select((appState) => appState.paymentStore)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          IOCLocalStorageItemKeys.productFee,
          JSON.stringify(res.productFee)
        );
      });
  }

  storeEORInfo(application) {
    localStorage.setItem(
      EORLocalStorageItemKeys.eorApplication,
      JSON.stringify(application)
    );
  }

  storeOSRInfo(application?: any) {
    this.store
      .select(selectSsrComponent)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          OSRLocalStorageItemKeys.ssrComponent,
          JSON.stringify(res)
        );
      });
    this.store
      .select(selectSSRFee)
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          localStorage.setItem(
            OSRLocalStorageItemKeys.ssrFee,
            JSON.stringify(res)
          );
        } else {
          this.store
            .select(selectOSRApplication)
            .pipe(first())
            .subscribe((app: Application) => {
              const ssrFee: ProductFee = this.paymentUtilService.getSsrFee(app);
              localStorage.setItem(
                OSRLocalStorageItemKeys.ssrFee,
                JSON.stringify(ssrFee)
              );
            });
        }
      });
    this.store
      .select(selectSelectedTest)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          OSRLocalStorageItemKeys.selectedTest,
          JSON.stringify(res)
        );
      });
    this.store
      .select(selectOfflinePayment)
      .pipe(first())
      .subscribe((res) => {
        localStorage.setItem(
          OSRLocalStorageItemKeys.offlinePayment,
          JSON.stringify(res)
        );
      });
    localStorage.setItem(
      OSRLocalStorageItemKeys.ssrApplication,
      JSON.stringify(application)
    );
  }
  storePaymentType(paymentType) {
    localStorage.setItem('paymentType', paymentType);
  }
  storePaymentGateway(paymentGateway) {
    localStorage.setItem('paymentGateway', paymentGateway);
  }
  storePaymentMethodType(paymentMethodType) {
    localStorage.setItem('paymentMethodType', paymentMethodType);
  }
  storePaymentState(paymentState) {
    localStorage.setItem('paymentState', paymentState);
  }
  storeBankAlfalahResult(paymentResult) {
    localStorage.setItem('bankAlfalahResult', JSON.stringify(paymentResult));
  }
  storeNewebpayDateTime(dateTime) {
    localStorage.setItem('newebpayDateTime', JSON.stringify(dateTime));
  }
  navigateOnPaymentType(paymentType) {
    switch (paymentType) {
      case 'IOC':
        return this.router.navigate(['/payment/confirmation']);
      case 'EOR':
      case 'OSR':
        return this.router.navigate(['/my-tests']);
      default:
        return this.router.navigate(['/my-tests']);
    }
  }
}
