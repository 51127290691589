import { Auth } from 'aws-amplify';
import { GtmHelper } from './shared/helper/gtm.helper';
import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { UserProfileService } from './shared/services/user-profile.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CacheService } from './shared/services/cache.service';
import { OneTrustService } from 'shared/services/one-trust.service';
const appSettings = require('src/assets/appSettings.json');
declare let dataLayer;
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'idp';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ globalStore }>,
    private gtmService: GoogleTagManagerService,
    private cacheService: CacheService,
    private gtmHelper: GtmHelper,
    private oneTrustservice:OneTrustService
  ) {
    window.dataLayer = window.dataLayer || [];
    this.cacheService.startEvent();
  }


  ngOnInit() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {

      const mainHeader: any = document.querySelector('#NavBar');
      if (mainHeader) {
        mainHeader.focus();
      }
    });
    this.store.select(appState => appState.globalStore).subscribe(x => {
      if (x?.enableOnetrustPolicy) {
        this.oneTrustservice.denyGrantGATag();
       } else {
        this.oneTrustservice.addGaScript();
      }
    });
   
    this.setGoogleAnalytics();
  }
  setGoogleAnalytics() {
    if (appSettings.site.environmentName === 'DEV' || appSettings.site.environmentName === 'TST' || appSettings.site.environmentName === 'SIT' || appSettings.site.environmentName === 'PROD') {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.gtmHelper.checkUserStatus();
        let tempRoute = this.route.snapshot;
        while (tempRoute.firstChild) {
          tempRoute = tempRoute.firstChild;
        }
        setTimeout(() => {
          const gtmTag = this.gtmHelper.getGtmEvents(tempRoute.data.pagename);
          this.gtmService.pushTag(gtmTag);
        }, 100);
      });
    }
  }
  @HostListener('window:beforeunload')
  clearLocalStorage() {
    this.cacheService.stop();
    localStorage.removeItem('page');
    localStorage.removeItem('prefilled');
    localStorage.setItem('emailID', '');
  }
}
